import { Fragment, useContext } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import { AuthContext } from "@ryanar/react-auth-provider";
import { identity, Deso } from "deso.js";

import Constants from "./Constants";

import Login from './Components/Login'


export default function Header(props) {
  const { setAuthenticated } = useContext(AuthContext);
  const { authenticated } = useContext(AuthContext);

  const login = async () => {
    const response = await identity.login({ accessLevel: 2 });
    console.log("res", response);

    const publicKey = response.publicKeyAdded;

    

    const deso = new Deso({ baseUrl: "https://node.deso.org/api" });
    const profile = await deso.getSingleProfile({
      publicKey,
    });

    
    const userObject = {
      publicKey,
      user: profile,
    };
    
    console.log(userObject);

    localStorage.setItem("userObject", JSON.stringify(userObject));
    setAuthenticated(userObject);

    return publicKey;
  };

  return (
    <Fragment>
      <Popover className="relative bg-white overflow-hidden">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto">
              <div className={`${props.hidden ? "pb-8" : "relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"}`}>
                <svg
                  className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                  fill="currentColor"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  aria-hidden="true"
                >
                  <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                  <nav
                    className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                    aria-label="Global"
                  >
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <a href="#">
                          <span className="sr-only">Workflow</span>
                          <img
                            className="h-8 w-auto sm:h-10"
                            src="../logo512.png"
                          />
                        </a>
                        <div className="-mr-2 flex items-center md:hidden">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                      {Constants.navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="font-medium text-gray-500 hover:text-gray-900"
                        >
                          {item.name}
                        </a>
                      ))}

                      {!!authenticated ? (
                        <Fragment>
                          
                          <a
                            href={`https://www.bitclout.com/u/${authenticated.user["Profile"]["Username"]}`}
                            class="font-medium text-gray-500 hover:text-gray-900"
                            target="_blank"
                          >
                            <div
                              class="h-8 w-8 max-w-xs max-h-xs rounded mr-1 inline-block align-middle"
                              style={{
                                backgroundImage: `url("https://bitclout.com/api/v0/get-single-profile-picture/${authenticated.user["Profile"]["PublicKeyBase58Check"]}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`,
                                backgroundSize: "cover",
                              }}
                            ></div>
                            {authenticated.user["Profile"]["Username"]}
                          </a>

                        </Fragment>
                      ) : <span class="font-medium text-gray-500 hover:text-gray-900"><Login/></span>}
                    </div>  
                  </nav>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    static
                    className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                  >
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                        <img
                            className="h-8 w-auto sm:h-10"
                            src="../logo512.png"
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Close main menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="px-2 pt-2 pb-3 space-y-1">
                        {Constants.navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </a>
                        ))}
                         {!!authenticated ? (
                        <Fragment>
                          
                          <a
                            href={`https://www.bitclout.com/u/${authenticated.user["Profile"]["Username"]}`}
                            class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                            target="_blank"
                          >
                            <div
                              class="h-8 w-8 max-w-xs max-h-xs rounded mr-1 inline-block align-middle"
                              style={{
                                backgroundImage: `url("https://bitclout.com/api/v0/get-single-profile-picture/${authenticated.user["Profile"]["PublicKeyBase58Check"]}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`,
                                backgroundSize: "cover",
                              }}
                            ></div>
                            {authenticated.user["Profile"]["Username"]}
                          </a>

                        </Fragment>
                      ) : <span class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"><Login/></span>}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
                              { !props.hidden && 
                <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-12 lg:px-12 xl:mt-12">
                  <div className="sm:text-center lg:text-left">
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                      <span className="block xl:inline">{props.title}</span>{" "}
                      <span className="block text-indigo-600 xl:inline">
                        {props.subtitle}
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      {props.description}
                    </p>
                  </div>
                </main>
                }
              </div>
            </div>

            { !props.hidden &&
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              
              <img
                className="h-56 w-full object-cover sm:h-72 md:h-72 lg:w-full lg:h-72"
                src={`https://images.unsplash.com/${props.unsplashId}?ixlib=rb-1.2.1&ixid=1zO4O3Z0UJA&auto=format&fit=crop&w=2850&q=80`}
                alt=""
              />
            </div>

            }
          </>
        )}
      </Popover>
    </Fragment>
  );
}
