import { React, Fragment, useState, useEffect } from "react";
import api from "../api.js";
import {
    diamondsByLevel,
    totalDiamondValue,
    diamondLevelFromDiamonds,
    diamondValueFromDiamonds,
  } from "../Helpers/diamond";

export default function EventDiamond(props) {
    const [loading, setLoading] = useState(true);
    const [diamondValue, setDiamondValue] = useState(0);
    const [diamondLevel, setDiamondLevel] = useState(0);
    const event = props.event;

    useEffect(async () => {
        if (event.eventSoDiamondPostHashHex) {
          const postHashHex = event.eventSoDiamondPostHashHex;
          const diamonds = await api.getDiamondsForPost(postHashHex);
        //   setDiamondValue(diamondValueFromDiamonds(diamonds));
          setDiamondLevel(diamondLevelFromDiamonds(diamonds));
        }
        setLoading(false);
      }, []);

    return (
        <span>
            {Array(diamondLevel).fill('💎') }
        </span>
    )
}