// { name: 'Luis Borges', href: '/luis-borges' },

export default {
    navigation: [  
      { name: 'Home', href: '/' },
      { name: 'Add Event', href: '/events/new' },
      { name: 'Leaderboard', href: '/leaderboard' },
    // { name: 'Calendar', href: '/calendar' },
    // { name: 'Bot', href: '/bot' },
    // { name: 'Feature Requests', href: '/feature-requests' },
    // { name: 'Watchlist', href: '/watchlist' },
    // { name: 'Prices', href: '/prices' }
  ],
  firebaseConfig: {
    apiKey: "AIzaSyBcA_QFH0Lc8tJVzjsw2H5HKrBr8rz-oA8",
        authDomain: "bitcloutoffers.firebaseapp.com",
        databaseURL: "https://bitcloutoffers-default-rtdb.firebaseio.com",
        projectId: "bitcloutoffers",
        storageBucket: "bitcloutoffers.appspot.com",
        messagingSenderId: "996107078847",
        appId: "1:996107078847:web:3051f05e825cadf1c68a0a",
        measurementId: "G-QF7WCW68ZW"
      }
}