import { Fragment, useContext } from "react";

import { AuthContext } from "@ryanar/react-auth-provider";

export default function Logout() {
  const { setAuthenticated } = useContext(AuthContext);
  const { authenticated } = useContext(AuthContext);

  const logout = () => {
      console.log("logged out");
      setAuthenticated(null)
      localStorage.setItem("userObject", null)
  }

  return (
      <Fragment>
          { authenticated && <div className="cursor-pointer" onClick={logout}>
              Logout
              </div>}
      </Fragment>
  )

}