import { Fragment } from "react";

export default function LoadingIcon() {
  return (
    <Fragment>
      <div className="loadingio-spinner-pulse-9slzfhsl4is">
        <div className="ldio-7liyw3ixn4k">
          <div />
          <div />
          <div />
        </div>
      </div>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n@keyframes ldio-7liyw3ixn4k-1 {\n  0% { top: 18px; height: 64px }\n  50% { top: 30px; height: 40px }\n  100% { top: 30px; height: 40px }\n}\n@keyframes ldio-7liyw3ixn4k-2 {\n  0% { top: 20.999999999999996px; height: 58.00000000000001px }\n  50% { top: 30px; height: 40px }\n  100% { top: 30px; height: 40px }\n}\n@keyframes ldio-7liyw3ixn4k-3 {\n  0% { top: 24px; height: 52px }\n  50% { top: 30px; height: 40px }\n  100% { top: 30px; height: 40px }\n}\n.ldio-7liyw3ixn4k div { position: absolute; width: 15px }.ldio-7liyw3ixn4k div:nth-child(1) {\n  left: 17.5px;\n  background: #1d3f72;\n  animation: ldio-7liyw3ixn4k-1 1s cubic-bezier(0,0.5,0.5,1) infinite;\n  animation-delay: -0.2s\n}\n.ldio-7liyw3ixn4k div:nth-child(2) {\n  left: 42.5px;\n  background: #5699d2;\n  animation: ldio-7liyw3ixn4k-2 1s cubic-bezier(0,0.5,0.5,1) infinite;\n  animation-delay: -0.1s\n}\n.ldio-7liyw3ixn4k div:nth-child(3) {\n  left: 67.5px;\n  background: #d8ebf9;\n  animation: ldio-7liyw3ixn4k-3 1s cubic-bezier(0,0.5,0.5,1) infinite;\n  animation-delay: undefineds\n}\n\n.loadingio-spinner-pulse-9slzfhsl4is {\n  width: 54px;\n  height: 54px;\n  display: inline-block;\n  overflow: hidden;\n  background: #f1f2f3;\n}\n.ldio-7liyw3ixn4k {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  transform: translateZ(0) scale(0.54);\n  backface-visibility: hidden;\n  transform-origin: 0 0; /* see note above */\n}\n.ldio-7liyw3ixn4k div { box-sizing: content-box; }\n/* generated by https://loading.io/ */\n",
        }}
      />
    </Fragment>
  );
}
