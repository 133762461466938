import { Fragment, useState, useEffect } from "react";
import api from "../api.js";
import dateFormatter from "../dateFormatter";
import Tag from "./Tag.js";
import EventVote from "./EventVote.js";
import { DateTime } from "luxon";

import EventDiamond from "./EventDiamond";
import LoadingIcon from "./LoadingIcon.js";

import {
  eventPhoto,
  eventPhotoUrl,
  eventUrl,
  addToGCal,
} from "../Helpers/event";

const endedHeaders = () => (
  <Fragment>
    <th class="w-2/6 text-left">Title</th>
    <th class="w-1/6 text-right">Start Time</th>
    <th class="w-1/6 text-right">End Time</th>
    <th class="w-1/6 text-right">Details</th>
  </Fragment>
);

const headers = (opts = {}) => (
  <Fragment>
    <th class="w-2/6 text-left">Title</th>
    <th class="w-1/6 text-right">{opts.time || "Start Time"}</th>
    <th class="w-1/6 text-right">Details</th>
  </Fragment>
);

export default function EventSection(props) {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { queryString, title, type, setEvent, exchangeRate } = props;

  const eventDate = (type, event) => {
    if (type === "ongoing") {
      dateFormatter.dateValue(event.endTime, event.precision, "end", {
        relative: true,
      });
    }

    if (type === "upcoming" || type === "new" || type === "promoted") {
      return dateFormatter.dateValue(
        event.startTime,
        event.precision,
        "start",
        {
          relative: true,
        }
      );
    }

    if (type === "ended") {
      const startLabel = dateFormatter.dateLabel(
        event.startTime,
        event.precision,
        "start"
      );

      const endLabel = dateFormatter.dateLabel(
        event.endTime,
        event.precision,
        "end"
      );
      return `${startLabel} - ${endLabel}`;
    }
  };

  const eventCreatedAt = (event) => {
    return (
      <div class="text-xs text-gray-500 mb-2 mt-1">
        Added by{" "}
        <a
          href={`https://diamondapp.com/u/${event.user.data.user.Profile.Username}`}
          class="font-semibold"
        >
          {event.user.data.user.Profile.Username}
        </a>{" "}
        ·{" "}
        {DateTime.fromISO(event.createdAt)
          .toRelative({ style: "narrow" })
          .replace(".", "")}
      </div>
    );
  };

  const eventTitle = (event) => (
    <>
    <a class="" target="_blank" href={eventUrl(event)}>
      <span class="font-semibold text-md underline"><EventDiamond event={event}/> {event.title}</span> 
    </a>
    </>
  );

  const eventImage = (event) => (
    <a href={eventPhotoUrl(event)}>
      <div
        class="h-24 w-24 max-w-xs max-h-xs rounded mr-2 inline-block align-middle	"
        style={{
          backgroundImage: eventPhoto(event),
          backgroundSize: "cover",
        }}
      ></div>
    </a>
  );

  const eventTags = (event) => {
    if (event.tags.length > 0 || event.users.length > 0) {
      return (
        <div class="flex-wrap space-y-1">
          {event.isRequiresNFT && (
            <Tag name={"NFT"} tagColor="green" class="text-xxs mr-1" />
          )}
          {event.tags.slice(0,3).map((tag) => (
            <Tag name={tag} tagColor="blue" class="text-xxs mr-1" />
          ))}
          {event.users.slice(0,3).map((user) => (
            <Tag name={user} isAt tagColor="green" class="text-xxs mr-1" />
          ))}
        </div>
      );
    }
  };

  const eventAddToCal = (type, event) => {
    if (type === "upcoming" || type === "new" || type === "promoted") {
      return (
        <div className="inline-flex items-center justify-center mr-2 px-2 py-1 border border-transparent font-xs font-bold rounded-md text-white bg-blue-600 hover:bg-blue-700">
          {addToGCal(event)}
        </div>
      );
    }
  };
  const eventView = (event) => (
    <a
      href={`/events/${event._id}`}
      className="inline-flex items-center justify-center px-2 py-1 cursor-pointer border border-transparent font-xs font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
    >
      View
    </a>
    // <div
    //   className="inline-flex items-center justify-center px-2 py-1 cursor-pointer border border-transparent font-xs font-bold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
    //   onClick={() => setEvent(event)}
    // >
    //   View Details
    // </div>
  );

  const dateLabel = (date, precision, timeZone) => {
    // fill this in
  };

  const calculatedCoinPrice = (CoinPriceBitCloutNanos) => {
    if (!exchangeRate["SatoshisPerBitCloutExchangeRate"]) {
      return;
    }

    const price =
      ((exchangeRate["SatoshisPerBitCloutExchangeRate"] /
        exchangeRate["USDCentsPerBitcoinExchangeRate"]) *
        CoinPriceBitCloutNanos) /
      1000000;

    return `$${price.toFixed(2)}`;
  };

  useEffect(async () => {
    const response = await api.getEvents(queryString);
    setLoaded(true);

    console.log("votes", response[0].votes, type);


    if (type === "promoted") {
      setData(
        response.sort((event1, event2) => event2.votes.length - event1.votes.length))
    } else {
      setData(
        response.filter((event) => event._id != "61e0f1426e2e4ad17e47339c")
      );
    }

  }, []);

  return (
    <Fragment>
      <div class="sm:hidden ml-2 mr-2 ">
        <div class="font-bold text-2xl mb-4">{title}</div>
        {!loaded && (
          <div class="mt-8">
            <div className="loadingio-spinner-pulse-9slzfhsl4is">
              <div className="ldio-7liyw3ixn4k">
                <div />
                <div />
                <div />
              </div>
            </div>
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  "\n@keyframes ldio-7liyw3ixn4k-1 {\n  0% { top: 18px; height: 64px }\n  50% { top: 30px; height: 40px }\n  100% { top: 30px; height: 40px }\n}\n@keyframes ldio-7liyw3ixn4k-2 {\n  0% { top: 20.999999999999996px; height: 58.00000000000001px }\n  50% { top: 30px; height: 40px }\n  100% { top: 30px; height: 40px }\n}\n@keyframes ldio-7liyw3ixn4k-3 {\n  0% { top: 24px; height: 52px }\n  50% { top: 30px; height: 40px }\n  100% { top: 30px; height: 40px }\n}\n.ldio-7liyw3ixn4k div { position: absolute; width: 15px }.ldio-7liyw3ixn4k div:nth-child(1) {\n  left: 17.5px;\n  background: #1d3f72;\n  animation: ldio-7liyw3ixn4k-1 1s cubic-bezier(0,0.5,0.5,1) infinite;\n  animation-delay: -0.2s\n}\n.ldio-7liyw3ixn4k div:nth-child(2) {\n  left: 42.5px;\n  background: #5699d2;\n  animation: ldio-7liyw3ixn4k-2 1s cubic-bezier(0,0.5,0.5,1) infinite;\n  animation-delay: -0.1s\n}\n.ldio-7liyw3ixn4k div:nth-child(3) {\n  left: 67.5px;\n  background: #d8ebf9;\n  animation: ldio-7liyw3ixn4k-3 1s cubic-bezier(0,0.5,0.5,1) infinite;\n  animation-delay: undefineds\n}\n\n.loadingio-spinner-pulse-9slzfhsl4is {\n  width: 54px;\n  height: 54px;\n  display: inline-block;\n  overflow: hidden;\n  background: #f1f2f3;\n}\n.ldio-7liyw3ixn4k {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  transform: translateZ(0) scale(0.54);\n  backface-visibility: hidden;\n  transform-origin: 0 0; /* see note above */\n}\n.ldio-7liyw3ixn4k div { box-sizing: content-box; }\n/* generated by https://loading.io/ */\n",
              }}
            />
          </div>
        )}

        {data &&
          data.map((event) => (
            <div
              style={{ borderTop: "1px solid lightGray" }}
              class="flex pt-4 mt-4"
            >
              <div class="flex-1">
                <div class="flex flex-col">
                  <div class="uppercase text-gray-600 text-xs">
                    {eventDate(type, event)}
                  </div>
                  <div>{eventTitle(event)}</div>
                  <div>{eventTags(event)}</div>
                  {type === "new" && eventCreatedAt(event)}

                  <div class="flex mt-8">
                    <div>{eventAddToCal(type, event)}</div>
                    <div>{eventView(event)}</div>
                  </div>
                </div>
              </div>

              <div>
                <div>{eventImage(event)}</div>
              </div>
            </div>
          ))}
      </div>

      <div class="hidden sm:block">
        <div class="font-bold text-2xl mb-4">{title}</div>
        <table class="table-fixed text-xs mb-8 ">
          <thead>
            <tr key={`${1}-${type}`}>
              {type == "ended" && endedHeaders()}
              {type == "ongoing" && headers({ time: "Ends in" })}
              {(type == "upcoming" || type === "new" || type === "promoted") &&
                headers({ time: "Start Time" })}
            </tr>
          </thead>

          {!loaded && (
            <div class="mt-8">
              <LoadingIcon/>
            </div>
          )}

          <tbody>
            {data &&
              data.map((event) => (
                <tr key={`${event._id}-${type}`}>
                  <td>
                    <div class="flex mb-2">
                      {(type == "upcoming" || type == "new" || type === "promoted") && (
                        <EventVote {...event} />
                      )}
                      <a href={eventPhotoUrl(event)}>
                        <div
                          class="h-10 w-10 max-w-xs max-h-xs rounded mr-2 inline-block align-middle	"
                          style={{
                            backgroundImage: eventPhoto(event),
                            backgroundSize: "cover",
                          }}
                        ></div>
                      </a>
                      <div>
                        <a
                          class="text-sm underline"
                          target="_blank"
                          href={eventUrl(event)}
                        >
                          <span class="font-semibold"><EventDiamond event={event}/> {event.title}</span>
                        </a>
                        <div>{eventTags(event)}</div>
                        {type === "new" && eventCreatedAt(event)}
                      </div>
                    </div>
                  </td>

                  {type == "ended" ? (
                    <Fragment>
                      <td class="text-right">
                        {dateFormatter.dateValue(
                          event.startTime,
                          event.precision,
                          "start"
                        )}
                      </td>
                      <td class="text-right">
                        {dateFormatter.dateValue(
                          event.endTime,
                          event.precision,
                          "end"
                        )}
                      </td>
                    </Fragment>
                  ) : (
                    <td class="text-right">
                      {type == "ongoing" &&
                        dateFormatter.dateValue(
                          event.endTime,
                          event.precision,
                          "end",
                          { relative: true }
                        )}
                      {(type == "upcoming" || type == "new" || type === "promoted") &&
                        dateFormatter.dateValue(
                          event.startTime,
                          event.precision,
                          "start",
                          { relative: true }
                        )}
                    </td>
                  )}

                  <td class="text-right">
                    {(type == "upcoming" || type == "new" || type === "promoted") && (
                      <div className="mb-0.5 mt-2 inline-flex items-center justify-center mr-2 px-2 py-1 border border-transparent font-xs font-bold rounded-md text-white bg-blue-600 hover:bg-blue-700">
                        {addToGCal(event)}
                      </div>
                    )}
                    {eventView(event)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* 
      <a href={type}>See more</a> */}
      </div>
    </Fragment>
  );
}
