import { Fragment, useState, useEffect } from "react";
import api from "./api.js";
import Header from "./Header";
import Footer from "./Footer";

export default function Leaderboard() {
  const [leaderboardUsers, setLeaderboardUsers] = useState([]);
  const [queryDays, setQueryDays] = useState(999999);

  const fetchLeaderboard = async (days) => {
    // const results = await api.getEvents(`verified=3&post.data.ProfileEntryResponse.CoinPriceBitCloutNanos=>1041000000&startTime=>${new Date()}&startTime=<${new Date(new Date().setFullYear(new Date().getFullYear() + 1))}`);
    // const results = await api.getEvents(`verified=3`);

    setQueryDays(days);
    const results = await api.getLeaderboard(`days=${days}`);

    setLeaderboardUsers(results.sort((a, b) => b.count - a.count));
  };

  useEffect(() => {
    fetchLeaderboard(999999);
  }, []);

  return (
    <Fragment>
      <Header
        title="EventSo Event Apes"
        subtitle="Who's adding the best online events?"
        description="Finding events isn't easy but a good event is everything"
        unsplashId="photo-1463852247062-1bbca38f7805"
      />

      <div class="md:container center grid place-items-center">
        <div class="m-8 text-2xl flex text-gray-600">
          <p
            class={`cursor-pointer mr-8 ${queryDays == 7 ? "font-bold" : ""}`}
            onClick={() => fetchLeaderboard(7)}
          >
            7d
          </p>
          <p
            class={`cursor-pointer mr-8 ${queryDays == 30 ? "font-bold" : ""}`}
            onClick={() => fetchLeaderboard(30)}
          >
            30d
          </p>
          <p
            class={`cursor-pointer mr-8 ${
              (queryDays == 999999 || undefined) ? "font-bold" : ""
            }`}
            onClick={() => fetchLeaderboard(999999)}
          >
            All Time
          </p>
        </div>

        {leaderboardUsers.map((user) => (
          <a href={`https://www.diamondapp.com/u/${user.username}`} target="_blank" class="mt-4">
          <div class="flex">
            <div
              class="h-24 w-24 max-w-xs max-h-xs rounded"
              style={{
                backgroundImage: `url("https://bitclout.com/api/v0/get-single-profile-picture/${user._id}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`,
                backgroundSize: "cover",
              }}
              ></div>
            <div class="ml-8 w-72">
              <div class="text-3xl">{user.username}</div>
              <div class="text-lg text-gray-500 font-semibold">Events added: {user.count}</div>
            </div>
          </div>
          </a>
        ))}
      </div>

      <Footer />
    </Fragment>
  );
}
