import { Fragment, useState, useContext } from "react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import Login from "./Login.js";
import api from "../api.js";

import { AuthContext } from "@ryanar/react-auth-provider";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

export default function EventVote(event, user, type) {
  const { authenticated } = useContext(AuthContext);

  const [votes, setVotes] = useState(event.votes || []);

  const hasUserVoted = (user, votes) => {
    if (!votes || votes.length == 0) {
      return false;
    }

    try {
      if (
        votes.find((votes) => votes.data.publicKey === user.publicKey) !==
        undefined
      ) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const eventVote = async () => {
    const eventId = event._id;
    const user = authenticated;

    const type = hasUserVoted(authenticated, votes) ? "unvote" : "vote";
    const data = { eventId, user, type };
    const newEvent = await api.voteEvent(data);

    setVotes(newEvent.votes || []);
  };

  return (
    <Fragment>
      {authenticated && (
        <div
          class={`text-center ${
            hasUserVoted(authenticated, votes) ? "text-blue-600 font-bold" : ""
          }`}
        >
          <div>
            <ChevronUpIcon
              className="h-6 w-6 cursor-pointer"
              aria-hidden="true"
              onClick={() => eventVote()}
            />
          </div>
          <div>{votes ? votes.length : 0}</div>
        </div>
      )}

      {!authenticated && (
        <div class={`text-center`}>
          <Popup
            trigger={
              <div>
                <div>
                  <ChevronUpIcon
                    className="h-6 w-6 cursor-pointer"
                    aria-hidden="true"
                  />
                </div>
                <div>{votes ? votes.length : 0}</div>
              </div>
            }
            position="top center"
          >
            <div class="text-sm text-center">Please <span class="cursor-pointer text-blue-500 underline"><Login/></span> to vote.</div>
          </Popup>
        </div>
      )}
    </Fragment>
  );
}
