import { Fragment, useState, useContext, useEffect } from "react";
import { DateTime } from "luxon";
import Header from "./Header.js";
import { get, useForm } from "react-hook-form";
import api from "./api.js";
import { AuthContext } from "@ryanar/react-auth-provider";

const hasPremiumAccess = () =>
  localStorage.getItem("bitcloutoffersPremiumCalendar") === "DiamondCalendar";

export default function New() {
  const { authenticated } = useContext(AuthContext);
    const [bookmarklet, setBookmarklet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    // form 1
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
      } = useForm({
        mode: "onBlur"
      });


    const setValues =  () => {
      

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      let title = params.title; 
      let url = params.url;
      let description = params.description;
      let bookmarklet = params.bookmarklet;

      if (!bookmarklet) { return; }

      setBookmarklet(!!bookmarklet);
      setValue("title", title);
      setValue("description", description);
      setValue("url", url);

      if (url.match(/(diamondapp|bitclout).com\/posts\//)) {
        var start = url.indexOf("posts/")
        var hex = url.substring(start+6,start+6+64);
        console.log(url, start, hex);
        setValue("postHash", hex);
      }
    }


    useEffect(() => {
      setValues();
    }, []);
  

    const onStartDateBlur = () => {
      let startTime = getValues("startTime");
      let endTime = getValues("endTime");
      
      endTime = endTime ? endTime : DateTime.fromISO(startTime).plus({hours: 1}).toISO().slice(0,16);

      setValue("endTime", endTime);
    }

      const onSubmit = async (data) => {
        function clearSubmission() {
          setError(false);
          setSubmitted(false);
          setLoading(false);
        }

          setLoading(true);

          data["user"] = { data: JSON.parse(localStorage.getItem("userObject"))};

          try {
            const post = await api.submitCommunityPost(data);

            if (!post) {
              setError(true);
              setTimeout(() => clearSubmission(), 3000);
            } else {
              setSubmitted(true);
            }

          } catch (error) {
            setError(true);
            setTimeout(() => clearSubmission(), 3000);
            return; 
          }
          // setPost(post);
          // const submitPost = await api.submitPost(data);


          setLoading(false);
      }

  return (
    <Fragment>
        
          <Header
            hidden={bookmarklet}
            title="Add Your Event"
            subtitle="to the DeSo List"
            description="Our community backed list of DeSo events"
            unsplashId="photo-1505373877841-8d25f7d46678"/>

        {/* <button onClick={login}>Login?</button> */}

        <div class="mt-8 font-bold text-xl">Submit a DeSo Event</div>
        <div class="text-xl mb-8">Next time, speed up your event creation, use the <a
                href="https://www.loom.com/share/17abd69197c64eab958a54fa41f24c83"
                class="text-blue-600"
              >
                EventSo Bookmarklet!
              </a></div>

        <form
            class="grid grid-cols-4 gap-2 mt-4 mb-40"
            onSubmit={handleSubmit(onSubmit)}
          >
            
          <label name="title" class="col-span-1">Title</label>
          <input
              placeholder="Your event name..."
              class="col-span-3 border inline-flex items-center justify-center px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="input"
              {...register("title", { required: true })}
            />


          <label name="Description" class="col-span-1">Description</label>
          <textarea
              placeholder="What's it about?"
              class="col-span-3 border inline-flex items-center justify-center px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="textarea"
              {...register("description", { required: false })}
          />

          <label name="DesoPostHex" class="col-span-1">Deso PostHex (optional, for profile pic)</label>
          <input
              placeholder="DeSo post id. DiamondApp.com/posts/***this value here***, don't include the ? or anything after it"
              class="col-span-3 border inline-flex items-center justify-center px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="input"
              {...register("postHash", { required: false, pattern: /^(?=(.{4})*$)[A-Za-z0-9+/]*([AQgw]==|[AEIMQUYcgkosw048]=)?$/i })}
          />

          <label name="url" class="col-span-1">Url (optional)</label>
          <input
              placeholder="Url for Clubhouse, Twitter Spaces"
              class="col-span-3 border inline-flex items-center justify-center px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="input"
              {...register("url", { required: false, pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i })}
          />

          {errors.postHash?.type === 'pattern' &&
          <Fragment>
            <div class="col-span-1"></div>
            <div class="col-span-3 text-blue-600">
              Whoops, just the id, DiamondApp.com/posts/***this value here***, don't include the ? or anything after it
            </div>
          </Fragment>
          }

          <label name="startTime" class="col-span-1">Start Time</label>
          <div class="col-span-1"></div>
          <input
              class="col-span-2 border px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="datetime-local" 
              {...register("startTime", { required: true, onBlur: (e) => onStartDateBlur(e) })}
          />
          <label name="endTime" class="col-span-1">End Time</label>
          <div class="col-span-1"></div>
          <input
              class="col-span-2 border px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="datetime-local"
              {...register("endTime")}
          />

          <label name="timezone" class="col-span-1">Time zone</label>
          <div class="col-span-1"></div>
          
          <style dangerouslySetInnerHTML={{__html: "\nselect {\n  -webkit-appearance: none;\n  appearance: none;\n}\n\n.select-wrapper {\n  position: relative;\n}\n\n.select-wrapper::after {\n  content: \"▼\";\n  font-size: 1rem;\n  top: 6px;\n  right: 10px;\n  position: absolute;\n}\n" }} />


          <select name="timezone" id="timezone" class="col-span-2 select-wrapper border px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo" {...register("timezone")}>
          <option value="America/New_York">EST - New York</option>
          <option value="America/Los_Angeles">PST - Los Angeles</option>
          <option value="America/Mexico_City">CST - Mexico City</option>
          <option value="Asia/Kolkata">IST - Kolkata</option>
          </select>


          <label name="tags" class="col-span-1">Tags</label>
          <input
              placeholder="Comma separated list of tags. eg. DeSo,Vibehut,Events"
              class="col-span-3 border inline-flex items-center justify-center px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="input"
              {...register("tags", { required: true })}
          />

          <label name="users" class="col-span-1">Users (optional)</label>
          <input
              placeholder="Comma separated list of DeSo usernames. Append @ to mention them eg. @EventSo,@OpenProsper,@Nader"
              class="col-span-3 border inline-flex items-center justify-center px-5 py-3 border border-indigo text-base font-medium rounded-md text-indigo"
              type="input"
              {...register("users", { required: false })}
          />

            { authenticated ? 
              <Fragment>
            { !submitted && !error &&
              <input
                type="submit"
                value="Submit Post"
                disabled={loading}
                class="cursor-pointer col-end-5 col-span-1 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              />
            }

            { submitted && !error && <input type="submit"
              disabled={true}
              value="Submitted - Thank you!"
              class="col-end-5 col-span-1 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-400 hover:bg-indigo-700">
              
              </input>}

              { error && <input type="submit" 
              disabled={true}
              value="Something went wrong :("
              class="cursor-not-allowed col-end-5 col-span-1 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700">
              </input>
              }
              </Fragment> :
              <Fragment>
                <div class="cursor-not-allowed col-end-5 col-span-1 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700">Login to submit an event</div>
              </Fragment>
            }

          </form>
    </Fragment>
  );
}



/*

Remove the deso post requirement
Add in an ability to add a link


*/


// { post && post["PostFound"] &&
// <Fragment>
  
// <div class="mt-8 col-span-4"></div>
// <div class="col-span-1 font-bold">Body</div>
// <div class="col-span-3">
//     { post["PostFound"]["Body"] }
// </div>

// <div class="col-span-1 font-bold">Username</div>
// <div class="col-span-3">
//     ${ post["PostFound"]["ProfileEntryResponse"]["Username"] }
// </div>

// <div class="mb-8 col-span-4"></div>


// </Fragment>
// }