// import Modal from "./Components/Modal";

import { Fragment, useState, useEffect } from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import EventRow from "./Components/EventRow.js";
import Constants from "./Constants";
import Footer from "./Footer";
// import { initializeApp } from 'firebase/app'

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { DateTime } from "luxon";
import PremiumCode from "./Components/PremiumCode";
import Sponsors from "./Components/Sponsors";
import Login from "./Components/Login";
import EventSection from "./Components/EventSection";
import PopularSection from "./Components/PopularSection";
import Header from "./Header.js";
import { get, useForm } from "react-hook-form";
import api from "./api.js";

const fetch = require("node-fetch");

// if (!firebase.apps.length) {
//   const firebaseApp = firebase.initializeApp(Constants.firebaseConfig);
// } else {
//   firebase.app(); // if already initialized, use that one
// }

// const firebaseAp = initializeApp(Constants.firebaseConfig);

// const hasPremiumAccess = () =>
//   localStorage.getItem("bitcloutoffersPremiumCalendar") === "DiamondCalendar";

// const db = firebase.firestore();

// function renderEventContent(info) {
//   var isPremium = info.event.extendedProps.premium === 1;

//   var date = DateTime.fromSeconds(info.event.extendedProps.DateValue.seconds)
//     .toLocaleString(DateTime.TIME_SIMPLE)
//     .replace(" ", "")
//     .replace(":00", "")
//     .toLowerCase();

//   if (info.event.extendedProps.DateAllDay) {
//     date = undefined;
//   }

//   if (isPremium) {
//     return (
//       <div class="overflow-x-hidden">
//         <div class="text-xs">
//           💎 {date}{" "}
//           <b>
//             {hasPremiumAccess()
//               ? info.event.extendedProps.Title
//               : "**Requires Diamond Passcode"}
//           </b>
//         </div>
//       </div>
//     );
//   } else {
//     return (
//       <div class="overflow-x-hidden">
//         <div class="text-xs">
//           {date} <b>{info.event.extendedProps.Title}</b>
//         </div>
//       </div>
//     );
//   }
// }

export default function Home() {
  // const [modal, setModal] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(false);
  const [event, setEvent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [leaderboardUsers, setLeaderboardUsers] = useState([]);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.setAttribute("embedclout", "bitcloutoffers");
  //   script.setAttribute("template", "light");
  //   script.setAttribute("position", "default");
  //   script.setAttribute("size", "detailed");
  //   script.src = "https://www.embedclout.com/badge/badge.js";

  //   document.querySelector("#embed").appendChild(script);
  // }, []);

  // const fetchToday = async () => {
  //   // let start = new Date("2021-05-16 12:01");
  //   // let end = new Date("2021-05-19 12:00");

  //   let start = DateTime.now().startOf("day").toJSDate();
  //   let end = DateTime.now().endOf("day").plus({ days: 2 }).toJSDate();

  //   let response = db
  //     .collection("events")
  //     .where("verified", "==", 1)
  //     .where("DateValue", ">=", start)
  //     .where("DateValue", "<", end)
  //     .limit(100);

  //   const data = await response.get();
  //   const results = data.docs.map((item) => item.data());

  //   // setToday(results);
  //   setIsLoading(false);
  // };

  // const fetchExchangeRate = async () => {
  //   const rate = await api.getExchangeRate;
  //   setExchangeRate(rate);
  // };

  const fetchLeaderboard = async () => {
    let results = await api.getLeaderboard(`days=${30}`);
    console.log("results", results);
    setLeaderboardUsers(results.sort((a, b) => b.count - a.count));
  };

  useEffect(async () => {
    // fetchExchangeRate();

    fetchLeaderboard();
    // fetchToday();

    // fetchTomorrow();
  }, []);

  return (
    <Fragment>
      {/* <Modal open={modal} close={() => setModal(false)} event={event} /> */}

      <Header
        title="Your upcoming interests"
        subtitle="in one place"
        description="Annoucements, NFT Giveaways, Twitter Spaces, Clubhouses all together"
        unsplashId="photo-1534484374439-6b8cd79be97c"
      />

      {/* queryString={`type=ongoing&users[]=1dolinski&users[]=jakeudell&startTime=>${encodeURI(new Date())}`} */}
      {/* <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"> */}

      <div class="flex justify-between mt-8">
        {/* <Login /> */}
        <div></div>

        <div class="flex ml-2 mr-2">
          <div>
            <a
              href="https://eventso.xyz/events/new"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Add an Event (DeSo, Twitter, Clubhouse)
            </a>
            <div class="text-right mt-2">
              <a
                href="javascript:q%3Dlocation.href%3Bif(document.getSelection)%7Bd%3Ddocument.getSelection()%3B%7Delse%7Bd%3D%27%27 %3B%7D%3Bp%3Ddocument.title%3Bvoid(open(%27https://eventso.xyz/events/new%3Fbookmarklet=true&url%3D%27%2BencodeURIComponent(q)%2B%27%26description%3D%27%2BencodeURIComponent(d)%2B%27%26title%3D%27%2BencodeURIComponent(p),%27EventSo%27,%27toolbar%3Dno,scrollbars%3Dyes,width%3D650,height%3D800%27))%3B"
                target="_blank"
                class="text-blue-600"
              >
                EventSo Bookmarklet
              </a>
            </div>
            <div class="text-right mt-2">
              <a
                href="https://www.loom.com/share/17abd69197c64eab958a54fa41f24c83"
                class="text-blue-600"
              >
                Why the bookmarklet is a game changer (2 min)
              </a>
            </div>
          </div>

          <div>
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/bitcloutoffers.appspot.com/o/events.ics?alt=media"
                className="inline-flex items-center justify-center ml-4 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Subscribe to Calendar 🗓
              </a>
            </div>
            <div class="text-right mt-2">
              <a
                href="https://bit.ly/3Axs5FX"
                target="_blank"
                class="text-blue-600"
              >
                Subscribe iCal (1:25min)
              </a>
            </div>
            <div class="text-right mt-2">
              <a
                href="https://www.businessinsider.com/how-to-subscribe-to-a-google-calendar"
                target="_blank"
                class="text-blue-600"
              >
                Subscribe gCal (link)
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-between">
        <div></div>
        <div class="flex mt-8">
          <div class="text-right">
            <p class="font-bold text-mb">Users Adding Events</p>
            <a
              href="https://eventso.xyz/leaderboard"
              class="pointer-cursor text-sm text-blue-600"
            >
              See all
            </a>
          </div>
          <div class="flex ml-4">
            {leaderboardUsers.slice(0, 3).map((user) => (
              <a
                href={`https://www.diamondapp.com/u/${user.username}`}
                target="_blank"
                class="mr-2"
              >
                <div
                  class="h-12 w-12 max-w-xs max-h-xs rounded"
                  style={{
                    backgroundImage: `url("https://bitclout.com/api/v0/get-single-profile-picture/${user._id}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 lg:grid-cols-2 mt-16 gap-4">
        <div class="">
          <EventSection
            title="Ongoing"
            type="ongoing"
            queryString={`type=ongoing`}
            setEvent={(event) => {
              setEvent(event);
              // setModal(true);
            }}
            exchangeRate={exchangeRate}
          />
        </div>
        <div>
        <EventSection
            title="Promoted and Upvoted"
            type="promoted"
            queryString={`type=upcoming`}
            setEvent={(event) => {
              setEvent(event);
            }}
            exchangeRate={exchangeRate}
          />

        </div>
      </div>

      <div class="grid sm:grid-cols-1 lg:grid-cols-2 mt-16 gap-4">
        <div class="">
          <EventSection
            title="Recently Added"
            type="new"
            queryString={`type=new`}
            setEvent={(event) => {
              setEvent(event);
            }}
            exchangeRate={exchangeRate}
          />
        </div>

        <EventSection
            title="Upcoming"
            type="upcoming"
            queryString={`type=upcoming`}
            setEvent={(event) => {
              setEvent(event);
            }}
            exchangeRate={exchangeRate}
          />



        <div></div>
        <div class="">
            
        </div>
        <div></div>
      </div>

      <div class="grid grid-cols-12 gap-1 mt-16">
        <div class="col-span-12">
          <EventSection
            title="Ended"
            type="ended"
            queryString={`type=ended`}
            setEvent={(event) => {
              setEvent(event);
              // setModal(true);
            }}
            exchangeRate={exchangeRate}
          />
        </div>
      </div>

      <div className="bg-gray-50 mt-16">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Want to add an event to the calendar?</span>
            <span className="block text-indigo-600">Add it here</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://eventso.xyz/events/new"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Add Event
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:items-center lg:justify-between">
        {/* <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          🎟 Upcoming BitClout Events
        </h2> */}

        {/* {isLoading && <p>Wait I'm loading events for you</p>} */}

        {/* {today.map((c, index) => (
            <div key={index}>
              {
                <>
                    <EventRow
                      premium={c.premium == 1}
                      allDay={c.DateAllDay}
                      dateValue={DateTime.fromJSDate(
                        c.DateValue.toDate()
                      ).toSeconds()}
                      timeZone={"America/New_York"}
                      url={`https://www.bitclout.com/posts/${c.PostHashHex}`}
                      title={c.Title}
                      createdAt={DateTime.fromJSDate(
                        c.UpdatedAt.toDate()
                      ).toSeconds()}
                      profilePic={c.ProfileEntryResponse.ProfilePic}
                      username={c.ProfileEntryResponse.Username}
                    />
                </>
              }
            </div>
          ))} */}
        {/* 
        <div className="mt-8">
          <a className="text-xl flex" href="/calendar">
            <p class="underline">More events - see the full calendar</p>
            <ArrowRightIcon className="h-6 w-6 mt-1" aria-hidden="true" />
          </a>
        </div> */}
      </div>

      {/* <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:items-center lg:justify-between">
    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">🐋 Creator Offers</h2>
    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 mb-2">
        The Most Promoted Offers on BitClout
    </p>

    {isLoading && <p>Wait I'm loading offers for you</p>}

    {offers.map((c, index) => (
        <div key={index}>
          { (
            <>
                <Row record={c} />
            </>
          )}
        </div>
      ))}

    <div className="mt-8">
        <a className="text-xl flex" href="/list">
            <p>See all offers</p>
            <ArrowRightIcon className="h-6 w-6 mt-1" aria-hidden="true"/></a>
    </div>
    </div> */}

      {/* <Sponsors /> */}
      {/* <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-left">
        <div id="embed"></div>
      </div> */}
    </Fragment>
  );
}
