import ListModal from "./Components/ListModal";

import { React, Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header.js";
import { DateTime } from "luxon";

import EventVote from "./Components/EventVote";
import { eventPhoto, eventPhotoUrl } from "./Helpers/event";
import { Dialog as div, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ArrowSmLeftIcon,
  LinkIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import dateFormatter from "./dateFormatter.js";
import Tag from "./Components/Tag";
import Linkify from "react-linkify";

import api from "./api.js";

import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import "./AvatarOverrides.css";
import {
  diamondsByLevel,
  totalDiamondValue,
  diamondLevelFromDiamonds,
  diamondValueFromDiamonds,
} from "./Helpers/diamond";

export default function Events() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState([]);
  const [modal, setModal] = useState(false);

  const [diamonds, setDiamonds] = useState([]);
  const [eventDiamonds] = useState([]);

  const componentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
      class="text-blue-600 text-weight-bold"
    >
      {text}
    </a>
  );

  const diamondTier = (value, description, enabled) => (
    <tr class="align-top">
      <td class={`w-10 pr-2 text-right ${enabled ? "font-bold" : ""}`}>
        {value}
      </td>
      <td class="w-4 pr-4">{enabled ? <span>✅</span> : <span>⬜️</span>}</td>
      <td>{description}</td>
    </tr>
  );

  useEffect(async () => {
    const event = await api.getEvent(id);

    if (event.eventSoDiamondPostHashHex) {
      const postHashHex = event.eventSoDiamondPostHashHex;
      const diamonds = await api.getDiamondsForPost(postHashHex);
      setDiamonds(diamonds);
    }
    setEvent(event);
    setLoading(false);
  }, []);

  return (
    <Fragment>
      <ListModal open={modal} close={() => setModal(false)} event={event} />
      <Header hidden />

      {loading && (
        <div class="max-w-xl w-screen mx-auto p-4 ">
          <div
            class="relative block p-8 overflow-hidden border border-gray-200 rounded-lg shadow-lg h-400"
            href=""
          >
            <div class="flex-1 space-y-6 py-1 h-72">
              <div class="h-2 bg-gray-200 rounded"></div>
              <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                  <div class="h-2 bg-gray-200 rounded col-span-2"></div>
                  <div class="h-2 bg-gray-200 rounded col-span-1"></div>
                </div>
                <div class="h-2 bg-gray-200 rounded"></div>
                <div class="h-2 rounded"></div>
                <div class="h-4 bg-gray-200 rounded"></div>
                <div class="h-14"></div>
                <div class="h-24 bg-gray-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <div class="max-w-xl w-screen mx-auto p-4">
          <a href="/">
            <div class="mb-4">
              <ArrowSmLeftIcon
                className="h-6 w-6 text-blue-400"
                style={{ display: "inline" }}
                aria-hidden="true"
              />{" "}
              <span class="text-gray-600">Back to home page</span>
            </div>
          </a>
          <div>
            <div
              class="relative block p-8 overflow-hidden border border-gray-200 rounded-lg shadow-lg"
              href=""
            >
              <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-purple-500 to-indigo-600"></span>

              <div class="flex">
                <div></div>
              </div>

              <div class="justify-between flex">
                <div>
                  <h4 class="text-md leading-6 font-semibold text-gray-500 pr-4">
                    {dateFormatter.dateRange(event.startTime, event.endTime)}
                  </h4>
                  <h5 class="text-xl font-bold text-gray-900">{event.title}</h5>
                  {event.user && (
                    <div class="mt-1 text-xs text-gray-600">
                      Added by{" "}
                      <a
                        href={`https://diamondapp.com/u/${event.user.data.user.Profile.Username}`}
                        class="font-semibold"
                      >
                        {event.user.data.user.Profile.Username}
                      </a>{" "}
                      ·{" "}
                      {DateTime.fromISO(event.createdAt)
                        .toRelative({ style: "narrow" })
                        .replace(".", "")}
                    </div>
                  )}
                </div>

                <a href={eventPhotoUrl(event)}>
                  <div
                    class="h-20 w-20 max-w-xs max-h-xs rounded mr-2 inline-block align-middle"
                    style={{
                      backgroundImage: eventPhoto(event),
                      backgroundSize: "cover",
                    }}
                  ></div>
                </a>
              </div>

              <div class="mt-4 sm:pr-8">
                <p class="text-sm text-gray-500 whitespace-pre-line">
                  <Linkify componentDecorator={componentDecorator}>
                    {event.description}
                    {event.url && (
                      <div class="mt-4 break-all">
                        <LinkIcon
                          className="h-4 w-4 text-gray-400 mr-1"
                          style={{ display: "inline" }}
                          aria-hidden="true"
                        />
                        {event.url}
                      </div>
                    )}
                  </Linkify>
                </p>
              </div>

              <dl class="flex mt-4">
                <dd class="text-xs text-gray-500">
                  {(event.tags.length > 0 || event.users.length > 0) && (
                    <div class="flex flex-wrap space-y-1">
                      {event.tags.map((tag) => (
                        <Tag name={tag} tagColor="blue" class="text-xxs mr-1" />
                      ))}

                      {event.users.map((user) => (
                        <Tag
                          name={user}
                          isAt
                          tagColor="green"
                          class="text-xxs mr-1"
                        />
                      ))}
                    </div>
                  )}
                </dd>
              </dl>

              <div class="mt-8 rounded-lg bg-gray-100 p-8">
                <p class="text-gray-600 text-md font-semibold">
                  Looking Forward To This
                </p>
                <p class="text-sm text-gray-600">
                  Upvote to show your interest
                </p>
                <div class="flex mt-4">
                  <EventVote {...event} />
                  <div class="ml-4" onClick={() => setModal(true)}>
                    <div class="flex">
                      <AvatarGroup total={event.votes.length}>
                        {event.votes.map((user) => (
                          <Avatar
                            class="hidden"
                            alt={user.data.user.Username}
                            style={{
                              backgroundImage: `url("https://bitclout.com/api/v0/get-single-profile-picture/${user.data.user.Profile["PublicKeyBase58Check"]}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`,
                              backgroundSize: "cover",
                            }}
                            src={`"")`}
                          ></Avatar>
                        ))}
                      </AvatarGroup>
                      {event.votes.length > 0 && (
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-500 mt-3"
                          style={{ display: "inline" }}
                          aria-hidden="true"
                        />
                      )}
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>
              {event.eventSoDiamondPostHashHex && (
                <div class="mt-4 border-gray-200 border rounded-lg p-8 ">
                  <div class="flex text-gray-600">
                    <div class="mr-2">💎</div>
                    <div>
                      <div class="font-semibold">
                        Current Diamond Value:{" "}
                        <span class="text-blue-500">
                          {" "}
                          ${diamondValueFromDiamonds(diamonds)}
                        </span>
                      </div>
                      <div class="text-sm text-gray-600 mb-4">
                        Reach will increase as more diamonds are given
                      </div>
                      <a
                        href={`https://www.diamondapp.com/posts/${event.eventSoDiamondPostHashHex}`}
                        target="_blank"
                        class="font-bold cursor-pointer center"
                      >
                        <div class="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mb-8 inline-block ">
                          Click here to diamond this event
                        </div>
                      </a>
                    </div>
                  </div>

                  <table class="text-gray-600">
                    {diamondTier(
                      "$200",
                      "EventSo will promote your event with someone on Fiverr",
                      diamondLevelFromDiamonds(diamonds) > 3
                    )}
                    {diamondTier(
                      "$50",
                      "Highlight this as premium on the homepage",
                      diamondLevelFromDiamonds(diamonds) > 2
                    )}
                    {diamondTier(
                      "$5",
                      "ReClout by EventSo and team",
                      diamondLevelFromDiamonds(diamonds) > 1
                    )}
                    {diamondTier(
                      "$1",
                      "EventSo will add a few extra diamonds",
                      diamondLevelFromDiamonds(diamonds) > 0
                    )}
                    {diamondTier(
                      "$0",
                      "Event was recently posted",
                      diamondLevelFromDiamonds(diamonds) >= 0
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div class="text-center font-semibold mt-16 text-gray-500">
        <a
          href="https://cold-acapella-1b7.notion.site/EventSo-FAQ-429d24e684c64a3dafb3c305eaee23a3"
          target="_blank"
        >
          What is EventSo?
        </a>
      </div>
      <div class="text-center mt-4 text-gray-500 mb-32">
        <a
          href="https://cold-acapella-1b7.notion.site/EventSo-FAQ-429d24e684c64a3dafb3c305eaee23a3"
          target="_blank"
        >
          How to subscribe with your iCal{" "}
          <span class="font-semibold underline">(video 2 min)</span>.{" "}
        </a>
        <a
          href="https://eventso.xyz/events/new"
          class="pointer-cursor font-semibold underline"
        >
          Add
        </a>{" "}
        events you're looking forward to! See the event hunter{" "}
        <a
          href="https://eventso.xyz/leaderboard"
          class="pointer-cursor font-semibold underline"
        >
          leaderboard
        </a>
        .
      </div>
    </Fragment>
  );
}
