import { useContext } from "react";
import { AuthContext } from "@ryanar/react-auth-provider";
import { identity, Deso } from "deso.js";

export default function Login() {

    const { setAuthenticated } = useContext(AuthContext);
    const { authenticated } = useContext(AuthContext);
  
    const login = async () => {
      const response = await identity.login({ accessLevel: 2 });
      console.log("res", response);
  
      const publicKey = response.publicKeyAdded;
  
      const deso = new Deso({ baseUrl: "https://node.deso.org/api" });
      const profile = await deso.getSingleProfile({
        publicKey,
      });
  
      
      const userObject = {
        publicKey,
        user: profile,
      };
      
      console.log(userObject);
  
      localStorage.setItem("userObject", JSON.stringify(userObject));
      setAuthenticated(userObject);
  
      return publicKey;
    };
  
    return (
        <a
        key="auth"
        className=""
        onClick={() => login()}
      >
        Login
      </a>
    )

}