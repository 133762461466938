
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { AuthProvider } from '@ryanar/react-auth-provider';

import './App.css';
import './index.css';

import Home from "./Home.js";
import List from "./List.js";
import Calendar from "./Calendar.js";
import Bot from "./Bot.js";
import FeatureRequest from "./FeatureRequest.js";
import EventStream from "./EventStream.js";
import WatchList from "./WatchList.js";
import Prices from "./Prices.js";
import New from "./New.js";
import Leaderboard from "./Leaderboard.js";
import Event from "./Event.js";

const trackingId = "UA-145092910-3"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default function App() {

  const handleLogin = () => {
    console.log("login?");
    // history.push('/');
  };

  const handleLogout = () => {
    console.log("logout");
    // history.push('/login');
  };


  return (
    <Router history={history}>
      <AuthProvider onLogin={handleLogin} onLogout={handleLogout} defaultAuthenticated={JSON.parse(localStorage.getItem("userObject"))}>
      <div class="container mx-auto">
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/events/new" component={New} />
          <Route path='/events/:id' component={Event} /> 
          <Route path="/list">
            <List />
          </Route>
          <Route path="/calendar">
            <Calendar />
          </Route>
          <Route path="/bot">
            <Bot />
          </Route>
          <Route path="/feature-requests">
            <FeatureRequest />
          </Route>
          <Route path="/event-stream">
            <EventStream />
          </Route>
          <Route path="/watchlist">
            <WatchList />
          </Route>
          <Route path="/prices">
            <Prices
             />
          </Route>
          <Route path="/leaderboard">
            <Leaderboard
             />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </AuthProvider>
    </Router>
  );
}