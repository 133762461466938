export const eventPhoto = (event) => {
    // image for event
    const genericHash =
      "742c3be14533666921ca27a82b30859cae480bac6f23ba1a5364903c65b5b1f9";
    if (event.post.data["PostHashHex"] !== genericHash) {
      // pointing to DeSo post
      return `url("https://bitclout.com/api/v0/get-single-profile-picture/${event.post.data["ProfileEntryResponse"]["PublicKeyBase58Check"]}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`;
    } else if (event.user) {
      // pointing to user who posted it
      return `url("https://bitclout.com/api/v0/get-single-profile-picture/${event.user.data.publicKey}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`;
    } else {
      // pointing to default EventSo image
      return `url("https://bitclout.com/api/v0/get-single-profile-picture/${event.post.data["ProfileEntryResponse"]["PublicKeyBase58Check"]}?fallback=https://bitclout.com/assets/img/default_profile_pic.png")`;
    }
  };
  
  export const eventPhotoUrl = (event) => {
    // url to profile
    const genericHash =
      "742c3be14533666921ca27a82b30859cae480bac6f23ba1a5364903c65b5b1f9";
    if (event.post.data["PostHashHex"] !== genericHash) {
      // pointing to DeSo post
      return `https://www.bitclout.com/u/${event.post.data["ProfileEntryResponse"]["Username"]}`;
    } else if (event.user) {
      // pointing to user who posted it
      return `https://www.bitclout.com/u/${event.user.data.user.Profile.Username}`;
    } else {
      // pointing to default EventSo image
      return `https://www.bitclout.com/u/${event.post.data["ProfileEntryResponse"]["Username"]}`;
    }
  
  };
  
export const eventUrl = (event) => {
    // url to url or deso post
  
    const genericHash =
      "742c3be14533666921ca27a82b30859cae480bac6f23ba1a5364903c65b5b1f9";
    if (event.url) {
      return event.url;
    } else if (event.post.data["PostHashHex"] !== genericHash) {
      return `https://www.bitclout.com/posts/${event.post.data.PostHashHex}`;
    } else if (event.user) {
      return `https://www.bitclout.com/u/${event.user.data.user["Profile"]["Username"]}`;
    }
  };

  export const addToGCal = (event) => {
    const baseUrl = "http://www.google.com/calendar/event";
    const url = new URL(baseUrl);
    url.searchParams.set("action", "TEMPLATE");
    url.searchParams.set(
      "dates",
      `${new Date(event.startTime)
        .toISOString()
        .replace(/\-|\.|\:/g, "")}/${new Date(event.startTime)
        .toISOString()
        .replace(/\-|\.|\:/g, "")}`
    );
    url.searchParams.set("details", `${event.description} ${event.url}`);
    url.searchParams.set("text", event.title);
  
    // 20210607T170000Z
    // 20220126T180000Z
  
    // http://www.google.com/calendar/event?action=TEMPLATE&dates=20220422%2F20220423&details=This+event+has+been+added+from+usehappen.com+-+Apr+22%2C+2022&location=&text=The+Batman+-+DC+FanDome+Teaser+-+YouTube
  
    return (
      <a target="_blank" href={url}>
        +🗓
      </a>
    );
  };