export const diamondsByLevel = (diamonds) => {
    const diamondLevels = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    };

    diamonds.DiamondSenders.forEach((sender) => {
      console.log("sender", sender, sender.DiamondLevel);
      diamondLevels[sender.DiamondLevel.toString()] =
        diamondLevels[sender.DiamondLevel] + 1;
    });

    return diamondLevels;
  };

  export const totalDiamondValue = (levels) => {
    const values = {
      1: 0.01,
      2: 0.02,
      3: 0.2,
      4: 2,
      5: 20,
      6: 200,
      7: 500,
      8: 2000,
    };

    const totalValue = ["1", "2", "3", "4", "5", "6", "7", "8"].reduce(
      (prev, current) => prev + levels[current] * values[current],
      0
    );

    console.log("total", totalValue);

    return totalValue;
  };

export  const diamondLevelFromDiamonds = (diamonds) => {
    const dByLevel = diamondsByLevel(diamonds);
    const dValue = totalDiamondValue(dByLevel);

    if (dValue > 500) { return 5; }
    if (dValue > 200) { return 4; }
    if (dValue > 20) { return 3; }
    if (dValue > 5) { return 2; }
    if (dValue > 1) { return 1; }
    return 0;
  }

  export const diamondValueFromDiamonds = (diamonds) => {
    const dByLevel = diamondsByLevel(diamonds);
    return totalDiamondValue(dByLevel);
  }